import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import CarsPage from './CarsPage';  // Import the CarsPage
import CallIcon from './CallIcon'; // Import CallIcon component

const HomePage = () => {
  return (
    <motion.div
      className="container mt-5"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="jumbotron text-center p-5">
        <h1>Welcome to CarOnDoor</h1>
        <p>Your reliable car rental service in Pune, Maharashtra, India. We specialize in outstation car rentals, airport pickups, and more.</p>
        <Link to="/cars" className="btn btn-secondary btn-lg p-2 m-2">
          Browse Cars
        </Link>
        <a href="tel:+917447449798" className="btn btn-success btn-lg p-2 m-2">
        <CallIcon />
          Book Now
        </a>
      </div>

      {/* Add CarsPage component below the jumbotron */}
      <CarsPage />
    </motion.div>
  );
};

export default HomePage;
