import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import ServicesPage from './components/ServicesPage';
import CarsPage from './components/CarsPage';
import NotFoundPage from './components/NotFoundPage';
import Footer from './components/Footer'; 
import TermsAndConditions from './components/TermsAndConditions';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/cars" element={<CarsPage />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="*" element={<NotFoundPage />} /> 
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
