import React from 'react';
import { motion } from 'framer-motion';

const services = [
  {
    title: 'Outstation Car Rentals',
    description: 'Explore the beauty of Maharashtra and beyond with our flexible outstation car rental services. Enjoy comfort and convenience as you travel to your desired destinations.',
  },
  {
    title: 'Airport Pickup and Drop Services',
    description: 'Experience hassle-free airport transfers with our reliable pickup and drop services. Our professional drivers will ensure you reach your destination safely and on time.',
  },
  {
    title: 'Hourly, Daily, and Long-Term Rentals',
    description: 'Whether you need a car for a few hours or several days, we offer flexible rental options tailored to your needs. Choose from a variety of plans that suit your travel requirements.',
  },
  {
    title: 'Well-Maintained Cars with Professional Drivers',
    description: 'Travel with peace of mind knowing that our cars are well-maintained and our drivers are experienced professionals. Your safety and comfort are our top priorities.',
  },
];

const ServicesPage = () => {
  return (
    <motion.div
      className="container mt-5"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <h2 className="text-center mb-4">Our Services</h2>
      <p className="text-center">
        At CarOnDoor, we provide a wide range of car rental services to meet your needs:
      </p>

      <div className="row">
        {services.map((service, index) => (
          <div className="col-md-6 mb-4" key={index}>
            <div className="card shadow">
              <div className="card-body">
                <h5 className="card-title">{service.title}</h5>
                <p className="card-text">{service.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <p className="text-center mt-4">
        For more details or bookings, contact us on <a href="tel:+917447449798">+91 7447449798</a> or WhatsApp us directly.
      </p>
    </motion.div>
  );
};

export default ServicesPage;
