import React, { useState, useEffect } from 'react';

const ImageLoader = ({ src, fallback, alt }) => {
  const [imageSrc, setImageSrc] = useState(src);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    // Reset state if src changes
    setImageSrc(src);
    setLoading(true);
    setHasError(false);

    const img = new Image();

    img.src = src;

    img.onload = () => {
      setLoading(false); // Image loaded successfully
    };

    img.onerror = () => {
      setHasError(true); // Mark as error
      setImageSrc(fallback); // Set fallback image if error occurs
      setLoading(false); // Mark loading as false
    };

    // Cleanup function to prevent memory leaks
    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [src, fallback]);

  return (
    <>
      {loading && !hasError && (
        <div className="skeleton-loader"></div> // Show skeleton loader while loading
      )}
      <img
        src={imageSrc}
        alt={alt}
        className="card-img-top"
        style={{ display: loading ? 'none' : 'block' }} // Hide image while loading
        onError={() => {
          setImageSrc(fallback); // Use fallback SVG image if onError
          setLoading(false); // Mark loading as false
        }}
      />
    </>
  );
};

export default ImageLoader; // Export the component
