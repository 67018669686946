import React from 'react';
import { motion } from 'framer-motion';

const TermsAndConditions = () => {
  return (
    <motion.div
      className="container mt-5"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <h2 className="text-center mb-4">Terms and Conditions</h2>

      <p className="text-center">
        Welcome to CarOnDoor! Before making a booking, please carefully read the following terms and conditions.
      </p>

      <h4>1. Booking Confirmation</h4>
      <p>
        All bookings are subject to confirmation. A valid booking will be confirmed via WhatsApp or SMS.
      </p>

      <h4>2. Rental Period</h4>
      <p>
        The rental period begins at the time specified in the booking and ends when the vehicle is returned.
      </p>

      <h4>3. Payment</h4>
      <p>
        Full payment is required at the time of booking.
      </p>

      <h4>4. Cancellation Policy</h4>
      <p>
        Cancellations made at least 24 hours before the scheduled pickup time will receive a full refund. Cancellations made within 24 hours will incur a 50% cancellation fee.
      </p>

      <h4>5. Driver Requirements</h4>
      <p>
        Renters must possess a valid driver's license. International renters must present a valid international driving permit.
      </p>

      <h4>6. Vehicle Usage</h4>
      <p>
        The vehicle must be used only for personal purposes. Off-road driving and unauthorized use are strictly prohibited.
      </p>

      <h4>7. Insurance Coverage</h4>
      <p>
        The rental includes basic insurance coverage. However, it is important to note that insurance is not covered by CarOnDoor. The car owner will take full responsibility for the insurance.
      </p>

      <h4>8. Responsibility for Damage</h4>
      <p>
        Renters are responsible for any damage to the vehicle during the rental period. In case of an accident, the renter must notify CarOnDoor immediately.
      </p>

      <h4>9. Liability Limitations</h4>
      <p>
        CarOnDoor is not liable for any indirect or consequential damages arising from the use of the vehicle.
      </p>

      <h4>10. Changes to Terms</h4>
      <p>
        CarOnDoor reserves the right to modify these terms and conditions at any time.
      </p>

      <h4>11. Governing Law</h4>
      <p>
        These terms and conditions shall be governed by the laws of India.
      </p>

      <h4>Contact Us</h4>
      <p>
        If you have any questions regarding these terms, please contact us at <a href="tel:+917447449798">+91 7447449798</a>.
      </p>

      <p className="text-center mt-4">
        By booking a vehicle with CarOnDoor, you acknowledge that you have read, understood, and agree to these terms and conditions.
      </p>
    </motion.div>
  );
};

export default TermsAndConditions;
