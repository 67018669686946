import React, { Suspense, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import WhatsAppIcon from './WhatsAppIcon'; // Import WhatsAppIcon component
import CallIcon from './CallIcon'; // Import CallIcon component
import ImageLoader from './ImageLoader'; // Import the ImageLoader component

const cars = [
  {
    model: 'Toyota Innova',
    rent: '₹3000/day',
    images: {
      sm: 'images/innova-sm.jpg', // Small device image
      lg: 'images/innova-lg.jpg', // Large device image
      xl: 'images/innova-xl.jpg', // Extra large device image
      fallback: 'images/fallback.svg',
    },
  },
  {
    model: 'Swift',
    rent: '₹2500/day',
    images: {
      sm: 'images/swift-sm.jpg', // Small device image
      lg: 'images/swift-lg.jpg', // Large device image
      xl: 'images/swift-xl.jpg', // Extra large device image
      fallback: 'images/fallback.svg',
    },
  },
  {
    model: 'Maruti Suzuki Dzire',
    rent: '₹2000/day',
    images: {
      sm: 'images/dzire-sm.jpg', // Small device image
      lg: 'images/dzire-lg.jpg', // Large device image
      xl: 'images/dzire-xl.jpg', // Extra large device image
      fallback: 'images/fallback.svg',
    },
  },
  {
    model: 'Maruti Suzuki Ertiga',
    rent: '₹3500/day',
    images: {
      sm: 'images/ertiga-sm.jpg', // Small device image
      lg: 'images/ertiga-lg.jpg', // Large device image
      xl: 'images/ertiga-xl.jpg', // Extra large device image
      fallback: 'images/fallback.svg',
    },
  },
  {
    model: 'Traveller',
    rent: '₹4000/day',
    images: {
      sm: 'images/traveller-sm.jpg', // Small device image
      lg: 'images/traveller-lg.jpg', // Large device image
      xl: 'images/traveller-xl.jpg', // Extra large device image
      fallback: 'images/fallback.svg',
    },
  },
  {
    model: 'Cruiser',
    rent: '₹4500/day',
    images: {
      sm: 'images/cruiser-sm.jpg', // Small device image
      lg: 'images/cruiser-lg.jpg', // Large device image
      xl: 'images/cruiser-xl.jpg', // Extra large device image
      fallback: 'images/fallback.svg',
    },
  },
  // Add more cars as needed...
];


const getImageSource = (images) => {
  const screenWidth = window.innerWidth; // Get the screen width

  if (screenWidth < 576) {
    // Small devices (phones)
    return images.sm || images.fallback;
  } else if (screenWidth >= 576 && screenWidth < 992) {
    // Medium devices (tablets)
    return images.sm || images.fallback; 
  } else if (screenWidth >= 992 && screenWidth < 1200) {
    // Large devices (desktops)
    return images.lg || images.fallback; 
  } else {
    // Extra large devices (larger desktops)
    return images.xl || images.lg || images.fallback; 
  }
};

const CarsPage = () => {
  const [fromDate, setFromDate] = useState({});
  const [toDate, setToDate] = useState({});
  const [showCalendar, setShowCalendar] = useState({});
  
  // State to hold the resolved image source
  const [imageSources, setImageSources] = useState(cars.map(car => getImageSource(car.images)));

  const toggleCalendar = (index) => {
    setShowCalendar((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleWhatsAppLink = (car, index) => {
    const fromDateString = fromDate[index] ? fromDate[index].toLocaleDateString() : '';
    const toDateString = toDate[index] ? toDate[index].toLocaleDateString() : '';
    const whatsappLink = `https://api.whatsapp.com/send?phone=917447449798&text=Hello,%20I%20am%20interested%20in%20renting%20the%20${encodeURIComponent(car.model)}%20at%20${encodeURIComponent(car.rent)}%20from%20${encodeURIComponent(fromDateString)}%20to%20${encodeURIComponent(toDateString)}&app_absent=0`;

    return whatsappLink;
  };

  // Update image sources on window resize
  useEffect(() => {
    const handleResize = () => {
      setImageSources(cars.map(car => getImageSource(car.images)));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="container mt-5">
      <h2>Available Cars for Rent</h2>
      <div className="row">
        {cars.map((car, index) => (
          <motion.div
            className="col-md-4"
            key={index}
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="card mb-4 position-relative">
              
              {/* Suspense for Image Loading */}
              <Suspense fallback={<div className="skeleton-loader" role="status"></div>}>
                <ImageLoader
                  src={imageSources[index]} // Use the dynamically resolved image source
                  fallback={car.images.fallback} // Fallback image
                  alt={car.model}
                />
              </Suspense>

              <div className="card-body">
                <h5 className="card-title">{car.model}</h5>
                <p className="card-text">Rent: {car.rent}</p>

                 {/* Display selected dates */}
                 {fromDate[index] && toDate[index] && (
                  <div className="alert alert-info mb-2">
                    <strong>Selected Dates:</strong> From {fromDate[index].toLocaleDateString()} to {toDate[index].toLocaleDateString()}
                  </div>
                )}
                {/* Calendar button */}
                {/* Conditional rendering for date pickers */}
                {showCalendar[index] && (
                   <div className="calendar-container">
                  <div className="row">
                    <div className="col-6 mb-2">
                      <label>From Date:</label>
                      <DatePicker
                        selected={fromDate[index] || null}
                        onChange={(date) => setFromDate((prev) => ({ ...prev, [index]: date }))}
                        className="form-control"
                        placeholderText="Select From Date"
                        popperPlacement="top-start`"
                        popperModifiers={{
                          flip: {
                              behavior: ["top-start"] // don't allow it to flip to be above
                          },
                          preventOverflow: {
                              enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                          },
                          hide: {
                              enabled: true // turn off since needs preventOverflow to be enabled
                          }
                      }}
                      />
                    </div>
                    <div className="col-6 mb-2">
                      <label>To Date:</label>
                      <DatePicker
                        selected={toDate[index] || null}
                        onChange={(date) => setToDate((prev) => ({ ...prev, [index]: date }))}
                        className="form-control"
                        placeholderText="Select To Date"
                        popperPlacement="top-start`"
                        popperModifiers={{
                          flip: {
                              behavior: ["top-start"] // don't allow it to flip to be above
                          },
                          preventOverflow: {
                              enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                          },
                          hide: {
                              enabled: true // turn off since needs preventOverflow to be enabled
                          }
                      }}
                      />
                    </div>
                  </div>
                  </div>
                )}
                <button
                  className="btn btn-secondary mb-3"
                  onClick={() => toggleCalendar(index)}
                >
                  Calendar
                </button>

                

                {/* WhatsApp and Call buttons */}
                <div className="d-flex flex-wrap justify-content-between">
                  <a
                    href={handleWhatsAppLink(car, index)}
                    className="btn btn-success me-2 mb-2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <WhatsAppIcon /> Book via WhatsApp
                  </a>

                  <a href="tel:+917447449798" className="btn btn-primary mb-2">
                    <CallIcon /> Call
                  </a>
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default CarsPage;
