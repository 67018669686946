import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-dark text-white py-4 mt-5">
      <div className="container text-center">
        <h5>CarOnDoor</h5>
        <p>Your reliable car rental service in Pune, Maharashtra, India.</p>

        <ul className="list-inline">
          <li className="list-inline-item">
            <Link to="/" className="text-white">Home</Link>
          </li>
          <li className="list-inline-item">
            <Link to="/about" className="text-white">About Us</Link>
          </li>
          <li className="list-inline-item">
            <Link to="/cars" className="text-white">Browse Cars</Link>
          </li>
          <li className="list-inline-item">
            <Link to="/services" className="text-white">Our Services</Link>
          </li>
          <li className="list-inline-item">
            <Link to="/terms" className="text-white">Terms & Conditions</Link>
          </li>
        </ul>

        <p>
          &copy; {new Date().getFullYear()} CarOnDoor. All Rights Reserved.
        </p>
        <p>
          Contact us: <a href="tel:+917447449798" className="text-white">+91 7447449798</a> | 
          <a href="https://api.whatsapp.com/send?phone=917447449798" target="_blank" rel="noopener noreferrer" className="text-white"> WhatsApp</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
