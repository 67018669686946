import React from 'react';
import { motion } from 'framer-motion';

const AboutUs = () => {
  return (
    <motion.div
      className="container mt-5"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <h2 className="text-center mb-4">About Us</h2>
      <div className="jumbotron text-center p-5">
        <h3>Welcome to CarOnDoor!</h3>
        <p>
          At CarOnDoor, we are passionate about providing exceptional car rental services tailored to your needs. Based in Pune, Maharashtra, we specialize in a wide range of rental options, from outstation journeys to airport pickups.
        </p>
        <p>
          Our mission is to ensure that your travel experience is as smooth and enjoyable as possible. With a diverse fleet of well-maintained vehicles and a commitment to customer satisfaction, we aim to make your ride comfortable and hassle-free.
        </p>
        <p>
          Whether you're planning a family trip, a business meeting, or a weekend getaway, CarOnDoor has the right vehicle for you. Our friendly and knowledgeable staff are here to assist you every step of the way, ensuring that your journey is seamless from start to finish.
        </p>
        <p>
          Choose CarOnDoor for your next car rental experience, and let us drive you to your destination with confidence and style!
        </p>
      </div>
      <div className="text-center">
        <h4>Why Choose Us?</h4>
        <ul className="list-unstyled about-services">
          <li>✅ Wide Range of Vehicles</li>
          <li>✅ Competitive Pricing</li>
          <li>✅ 24/7 Customer Support</li>
          <li>✅ Easy Booking Process</li>
          <li>✅ Trusted by Customers</li>
        </ul>
      </div>
    </motion.div>
  );
};

export default AboutUs;
